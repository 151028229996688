import { Controller } from "@hotwired/stimulus"
import {leave} from 'el-transition'; 

export default class extends Controller {
  connect() {
    this.boundPerform = this.perform.bind(this)
    document.addEventListener("turbo:before-visit", this.boundPerform)
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.boundPerform)
  }

  perform(e) {
    console.log("performing", e)
    leave(this.element)
  }
}
